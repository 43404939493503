'use client';

import useAuth from '@/contexts/AuthProvider';
import Button from '@/labtest/presentation/atoms/Button';
import useLabCart from '@labServices/context/CartContext';
import useToggle from '@labServices/hooks/use-toggle';
import BookforItem from '@labtest/presentation/atoms/BookforItem';
import Dropdown from '@labtest/presentation/atoms/Dropdown';
import Modal from '@labtest/presentation/atoms/Modal';
import StateFullModal from '@labtest/presentation/atoms/Modal/StateFullModal';
import ModalHeader from '@labtest/presentation/molecules/ModalHeader';
import PopUpModal from '@labtest/presentation/organisms/PopUpModal';

import { cartStorageName } from '@/services/ecommerce/apis/config';
import { IG_LT_MostBookedTest } from '@labServices/apis/types/lab-tests/type.getMostBookedLabTests';
import { IG_LT_TestDetails } from '@labServices/apis/types/lab-tests/type.getTestOrPackageDetails';
import { getItemFromLocalStorage } from '@labServices/utils/session';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './BookTestButton.module.scss';

interface I_Props {
	testId: string;
	testData: IG_LT_MostBookedTest | IG_LT_TestDetails;
	setShowLoginModal: Dispatch<SetStateAction<boolean>>;
	placeHolder?: string;
	btnClassName?: string;
	dropDownClassName?: string;
	selectedLabel?: string;
	name?: string;
}

const BookTestButton = ({
	testId,
	testData,
	setShowLoginModal,
	placeHolder = 'Book Test',
	btnClassName = null,
	dropDownClassName = null,
	selectedLabel = null,
	name
}: I_Props) => {
	const { cartData, cartAction, removeCartItem } = useLabCart();
	const [notAvailableRes, setNotAvailableRes] = useState({});
	const [openNotfound, handleOpenNotFound, handleCloseNotfound] = useToggle();
	const [openDialogModal, handleOpenDialogModal, handleCloseDialogModal] = useToggle();
	const { isLogged } = useAuth();

	const cartObj = getItemFromLocalStorage(cartStorageName);

	const patientArray = [1, 2, 3, 4, 5];
	let currentItem;
	if (cartObj?.items && cartData?.items) {
		currentItem = cartData?.items?.find((item) => item?.item?.id === testId);
	}
	const isAlreadyInCart = currentItem ? true : false;

	useEffect(() => {
		if (currentItem) {
			patientArray.shift();
		} else {
			patientArray.unshift('Remove Test');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [cartData]);

	const handleNotFoundModal = (res) => {
		setNotAvailableRes(res);
		handleOpenNotFound();
	};

	const handleBookForSelect = async (num) => {
		if (num === 'Remove Test') {
			removeCartItem(testId, handleCloseDialogModal);
			return;
		}

		await cartAction(testId, testData, num, handleNotFoundModal);
		handleCloseDialogModal();
		return;
	};

	return (
		<>
			{isAlreadyInCart ? (
				<div className={dropDownClassName}>
					{selectedLabel && (
						<p className='ltbody_lg__medium ltGreyscale600'>
							<span></span>
							{selectedLabel}
						</p>
					)}
					<Dropdown
						onClick={(e) => {
							e.stopPropagation();
							handleOpenDialogModal();
						}}
						placeHolder={`${currentItem?.patientCount} ${currentItem?.patientCount > 1 ? 'Patients' : 'Patient'}`}
						btnVariant={`active`}
						btnSize={`${name === 'cart_btn' ? 'xs' : 'fullWidth'}`}
						textSize={`${name === 'cart_btn' && 'ltbody_sm__regular'}`}
					/>
				</div>
			) : (
				<div className={styles.testCard_patient_btn}>
					<Button
						variant='bgPrimary'
						size={`${name === 'cart_btn' ? 'xs' : 'fullWidth'}`}
						onClick={(e) => {
							e.stopPropagation();
							isLogged ? handleOpenDialogModal() : setShowLoginModal(true);
						}}>
						{placeHolder}
					</Button>
				</div>
			)}

			<Modal openModal={openDialogModal} closeModal={handleCloseDialogModal}>
				<ModalHeader title='Book for' closeModal={handleCloseDialogModal} />
				{isAlreadyInCart && (
					<BookforItem
						key={'Remove Test'}
						onClick={(e) => {
							e.stopPropagation();
							handleBookForSelect('Remove Test');
						}}>
						<p className={`ltbody_md__regular errorBase`}>{`Remove Test`}</p>
					</BookforItem>
				)}
				{patientArray?.map((num) => (
					<BookforItem
						key={num}
						checked={currentItem?.patientCount === num}
						onClick={(e) => {
							e.stopPropagation();
							handleBookForSelect(num);
						}}>
						<p className={`ltbody_md__regular ${String(num) === 'Remove Test' ? 'errorBase' : ''}`}>
							{`${num} ${num > 1 ? 'Patients' : 'Patient'}`}
						</p>
					</BookforItem>
				))}
			</Modal>

			{openNotfound && (
				<StateFullModal open={openNotfound} closeModal={handleCloseNotfound}>
					<PopUpModal
						modalType={`not_available`}
						title={`Sorry`}
						errorTag={notAvailableRes?.data?.errorTag}
						message={notAvailableRes?.message}
						cancelDelete={(e) => {
							e.stopPropagation();
							handleCloseNotfound();
						}}
					/>
				</StateFullModal>
			)}
		</>
	);
};

export default BookTestButton;
