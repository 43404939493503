import ListItem from '@/labtest/presentation/atoms/ListItem';
import Spinner from '@labtest/presentation/atoms/Loaders/Spinners';

import { getTestOrPackageDetails } from '@labServices/apis/lab-test-apis/tests';
import { IG_LT_TestDetails } from '@labServices/apis/types/lab-tests/type.getTestOrPackageDetails';
import { isEmptyArray, isEmptyObject } from '@labServices/utils/cmnHelper';
import { v1_lab_science } from '@labServices/utils/svg';
import { useEffect, useState } from 'react';
import { FaXmark } from 'react-icons/fa6';

import style from './PackageDetailsDrawer.module.css';

interface I_Props {
	slug: string;
	data?: Partial<IG_LT_TestDetails>;
	onClose: () => void;
}

const PackageDetailsDrawer = ({ slug, data, onClose }: I_Props) => {
	const [packageDetailsData, setPackageDetailsData] = useState<Partial<IG_LT_TestDetails>>({});
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		setLoading(true);
		const handleGetTestOrPackageDetails = async () => {
			const res = await getTestOrPackageDetails(slug);
			if (res?.success) {
				setPackageDetailsData(res?.data);
			}
			setLoading(false);
		};

		if (slug) {
			handleGetTestOrPackageDetails();
		} else if (!isEmptyObject(data)) {
			setPackageDetailsData(data);
		}
		setLoading(false);
	}, [slug, data]);

	return (
		<>
			<div className={style.testDetails_head}>
				<div>
					<span>{v1_lab_science}</span>
				</div>
				<div>
					<p className='ltbody_lg__semibold'>{packageDetailsData?.name?.en}</p>
					<p className='ltbody_lg__regular'>
						{packageDetailsData?.testCount
							? packageDetailsData?.testCount <= 1
								? '1 Test'
								: `${packageDetailsData?.testCount} Tests`
							: '1 Test'}
					</p>
				</div>
				<div className={style.close_icon_wrap} onClick={onClose}>
					<FaXmark size={24} />
				</div>
			</div>

			{loading && <Spinner />}

			{!loading && !isEmptyArray(packageDetailsData?.tests) && (
				<div className={style.testDetails_lists}>
					{packageDetailsData?.tests?.map((test) => {
						return (
							<ListItem
								key={test?.id}
								name={test?.test?.name?.en || test?.name?.en}
								size={24}
								textSize={'ltbody_lg__regular'}
							/>
						);
					})}
				</div>
			)}
		</>
	);
};

export default PackageDetailsDrawer;
