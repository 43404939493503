import { toQueryString } from '@labServices/utils/cmnHelper';
import { request } from '../arogga-api';

import querystring from 'qs';
import { IG_LT_Category } from '../types/lab-tests/type.getLabTestsCategories';
import { IG_LT_MostBookedTest } from '../types/lab-tests/type.getMostBookedLabTests';
import { IG_LT_PopularLabTest } from '../types/lab-tests/type.getPopularLabItems';
import { IG_LT_RecommendedTest } from '../types/lab-tests/type.getRecommendedTests';
import { IG_LT_TestFAQ } from '../types/lab-tests/type.getTestDetailsFAQs';
import { IG_LT_TestRiskAssessment } from '../types/lab-tests/type.getTestDetailsRiskAssessments';
import { IG_LT_TestDetails } from '../types/lab-tests/type.getTestOrPackageDetails';
import { I_ResponseDataArray, I_ResponseDataObj } from '../types/type.lab-test-apis';

export const getLabItemsSearchData = (q: any) => {
	if ('_perPage' in q) {
		q['limit'] = q._perPage;
	}
	if ('_page' in q) {
		q['page'] = q._page;
	}
	if ('_search' in q) {
		q['query'] = q._search;
	}

	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search?${qs}`,
		method: 'GET'
	});
};

export const getLabItemsTrendingSearchData = (
	q = {
		_page: 1,
		_perPage: 10,
		_search: ''
	}
) => {
	if ('_perPage' in q) {
		q['limit'] = q._perPage;
	}
	if ('_page' in q) {
		q['page'] = q._page;
	}
	if ('_search' in q) {
		q['query'] = q._search;
	}
	const qs = querystring.stringify(q);

	return request({
		endpoint: `lab-search/api/v1/search/trending?${qs}`,
		method: 'GET'
	});
};

export const getMostBookedLabTests = (q: any): Promise<I_ResponseDataArray<IG_LT_MostBookedTest>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/frequent?${qs}`,
		method: 'GET'
	});
};

export const getPopularLabItems = (q: any): Promise<I_ResponseDataArray<IG_LT_PopularLabTest>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/lab-items/popular?${qs}`,
		method: 'GET'
	});
};

export const getLabTestsCategories = (q: any): Promise<I_ResponseDataArray<IG_LT_Category>> => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `misc/api/v1/category?${qs}`,
		method: 'GET'
	});
};

export const getLabTestsFilterCategories = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search/category?${qs}`,
		method: 'GET'
	});
};
export const GetSearchCounts = (q = '') => {
	return request({
		endpoint: `lab-search/api/v1/search/category?query=${q}&limit=1`,
		method: 'GET'
	});
};

export const getLabTestsFilterSections = (q = '') => {
	const qs = querystring.stringify(q);
	return request({
		endpoint: `lab-search/api/v1/search/sections?${qs}`,
		method: 'GET'
	});
};

export const getLabTests = (q: Record<string, any>) => {
	const qs = toQueryString(q);
	return request({
		endpoint: `misc/api/v1/lab-items?${qs}`,
		method: 'GET'
	});
};

export const getTestOrPackageDetails = (slug: string): Promise<I_ResponseDataObj<IG_LT_TestDetails>> => {
	return request({
		endpoint: `misc/api/v1/lab-items/${slug}`,
		method: 'GET'
	});
};

export const getRecommendationLabTests = (testId: string): Promise<I_ResponseDataArray<IG_LT_RecommendedTest>> => {
	return request({
		endpoint: `misc/api/v1/lab-items/recommendation?itemId=${testId}`,
		method: 'GET'
	});
};

export const getLabTestProcessSteps = () => {
	return request({
		endpoint: `misc/api/v1/lab-steps`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getLabTestRiskAssessments = (testId: string): Promise<I_ResponseDataArray<IG_LT_TestRiskAssessment>> => {
	return request({
		endpoint: `misc/api/v1/lab-items/details?itemId=${testId}`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getLabTestFAQs = (testId: string): Promise<I_ResponseDataArray<IG_LT_TestFAQ>> => {
	return request({
		endpoint: `misc/api/v1/lab-items/faq?itemId=${testId}`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};

export const getMinMaxPriceRange = () => {
	return request({
		endpoint: `misc/api/v1/lab-items/price-range`,
		method: 'GET',
		revalidateTime: 60 * 60 // 1 hour
	});
};
