'use client';
import Button from '@/labtest/presentation/atoms/Button';
import Line from '@/labtest/presentation/atoms/Line';
import { userStorageName } from '@/services/ecommerce/apis/config';
import { getUserAddresses, setNewAddress, updateAddress } from '@labServices/apis/arogga-api';
import { useAuthContext } from '@labServices/context';
import useToggle from '@labServices/hooks/use-toggle';
import { isEmptyObject, validPhoneNumber } from '@labServices/utils/cmnHelper';
import { getItemFromLocalStorage } from '@labServices/utils/session';
import BookforItem from '@labtest/presentation/atoms/BookforItem';
import Dropdown from '@labtest/presentation/atoms/Dropdown';
import Input from '@labtest/presentation/atoms/Input';
import Label from '@labtest/presentation/atoms/Label';
import Popover from '@labtest/presentation/atoms/Popover';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from './SampleCollectionAddress.module.css';

const SampleCollectionAddress = ({ handleModalClose, addressEdit = {}, setRefetch, refetch }) => {
	const { setUserData } = useAuthContext();
	const [open, handleOpen, handleClose] = useToggle();
	const [open2, handleOpen2, handleClose2] = useToggle();
	const [open3, handleOpen3, handleClose3] = useToggle();
	const user = getItemFromLocalStorage(userStorageName);

	const router = useRouter();

	const [state, setState] = useState({
		loading: false,
		connected: false,
		divisions: ['Select region'],
		districts: ['Select district'],
		areas: ['Select area'],
		l_division: addressEdit?.l_division || '',
		l_district: addressEdit?.l_district || '',
		l_area: addressEdit?.l_area || '',
		ul_name: addressEdit?.ul_name || user?.u_name,
		ul_mobile: addressEdit?.ul_mobile || user?.u_mobile,
		ul_address: addressEdit?.ul_address || '',
		ul_type: addressEdit?.ul_type || 'Home',
		ul_default: addressEdit?.ul_default || '',
		index: null,
		editing: false,
		ul_lat: 0,
		ul_long: 0,
		showError: false,
		shouldUpdate: true,
		allLocation: {}
	});

	//set alllocation
	useEffect(() => {
		setState((prev) => ({
			...prev,
			loading: true
		}));

		/* allLocations()?.then((response) => {
      if (response.status === 'success') {
        setState((prev) => ({
          ...prev,
          allLocation: response.data,
        }));
      }
    }); */
	}, []);

	const { allLocation, l_division, l_district, l_area, loading, divisions, districts, areas, ul_default, ul_type } =
		state;

	//set state
	useEffect(() => {
		if (!isEmptyObject(allLocation)) {
			setState((prev) => ({
				...prev,
				divisions: [...Object.keys(allLocation || {})],
				districts:
					!!l_division && l_division !== 'Select region'
						? [...Object.keys(allLocation[l_division] || {})]
						: ['Select district'],
				areas:
					!!l_division && l_division !== 'Select region' && !!l_district && l_district !== 'Select district'
						? [...Object.keys(allLocation[l_division][l_district] || {})]
						: ['Select area']
			}));
		}
	}, [l_division, l_district, l_area, allLocation]);

	// update address and set default address
	const updateLocation = (itemId, location) => {
		// setLoading(true);
		updateAddress(itemId, location)?.then((res) => {
			if (res.status === 'success') {
				setUserData((prev) => ({
					...prev,
					defaultAddress: res?.data?.ul_location
				}));
				setRefetch(!refetch);
			} else {
				// Swal.fire('Error!', res.message, 'error');
			}
			// setLoading(false);
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const {
			allLocation,
			l_division,
			l_district,
			l_area,
			ul_name,
			ul_mobile,
			ul_address,
			ul_type,
			ul_default,
			ul_lat,
			ul_long
		} = state;
		setState({
			...state,
			showError: true
		});
		if (!validPhoneNumber(ul_mobile)) {
			return alert('Please enter a valid 11 digit mobile number.');
		}
		if (!!allLocation && !!l_division && !!l_district && !!l_area && !!ul_name && !!ul_address) {
			const locationIdObj = allLocation[l_division][l_district][l_area];
			const location = {
				ul_name,
				ul_mobile,
				ul_type,
				ul_default,
				ul_address,
				l_division,
				l_district,
				l_area,
				ul_lat,
				ul_long,
				ul_location: `${ul_address}, ${l_area}, ${l_district}, ${l_division}`,
				l_id: locationIdObj?.l_id
			};
			setState({ loading: true });

			if (!!Object.keys(addressEdit).length) {
				updateAddress(addressEdit?.ul_id, location)
					?.then((res) => {
						if (res.status === 'success') {
							handleModalClose();
							toast('Address updated successfully!', { type: 'success' });
							setRefetch();
						} else {
							toast('Something went wrong!', { type: 'error' });
						}
					})
					.finally(() => setState({ loading: false }));
			} else {
				setNewAddress(location)
					?.then((res) => {
						if (res.status === 'success') {
							getUserAddresses().then((res) => {
								if (res.status === 'success') {
									//  update address and set default address
									if (res?.data?.length === 1) {
										const location = { ...res?.data[0] };
										location.ul_default = 1;
										// updateLocation(res?.data[0]?.ul_id, location);

										// add default address to the Cart Address
										// updateCartAddress(res?.data[0]?.ul_id);
									}
									setRefetch(refetch);
								}
							});
							handleModalClose();
							toast('Address added successfully!', { type: 'success' });
							setRefetch();
						} else {
							toast('Something went wrong!', { type: 'error' });
						}
					})
					.finally(() => setState({ loading: false }));
			}
		} else {
			toast('Please fill all the required field!', { type: 'warning' });
		}
	};

	return (
		<div className={styles.sampleCollectionAddress_wrap}>
			<div className={styles.sampleCollectionAddress}>
				<div className={styles.sampleCollectionAddress_row1}>
					<Input
						variant='outlined'
						value={state.ul_name}
						onChange={(e) => setState((prev) => ({ ...prev, ul_name: e.target.value }))}
						placeholder='Enter name'
						label='Name'
						required
					/>
					<Input
						variant='outlined'
						value={state.ul_mobile}
						onChange={(e) => setState((prev) => ({ ...prev, ul_mobile: e.target.value }))}
						placeholder='Enter Phone Number'
						label='Phone Number'
						required
					/>
				</div>
				<div className={styles.sampleCollectionAddress_row1}>
					<div className='fullWidth'>
						<Label label='Division' required />
						<Dropdown value={state.l_division} placeHolder={'Select Division'} onClick={handleOpen} fullWidth>
							<Popover variant='fullWidth' open={open} handleClose={handleClose}>
								<div className='p_10 flex justify-between'>
									<p className='ltbody_lg__semibold'>Select Division</p>
									<i onClick={handleClose} className='fa-solid fa-xmark fa-lg' />
								</div>
								<Line />
								{divisions?.map((div) => {
									return (
										<BookforItem
											key={div}
											checked={state.l_division == div}
											onClick={() => {
												setState({
													...state,
													l_division: div,
													l_district: '',
													l_area: ''
												});
												handleClose();
											}}>
											{div}
										</BookforItem>
									);
								})}
							</Popover>
						</Dropdown>
					</div>
					<div className='fullWidth'>
						<Label label='District' required />
						<Dropdown value={state.l_district} placeHolder={'Select District'} onClick={handleOpen2} fullWidth>
							<Popover variant='fullWidth' open={open2} handleClose={handleClose2}>
								<div className='p_10 flex justify-between'>
									<p className='ltbody_lg__semibold'>Select District</p>
									<i onClick={handleClose2} className='fa-solid fa-xmark fa-lg' />
								</div>
								<Line />
								{districts?.map((district) => {
									return (
										<BookforItem
											key={district}
											checked={state.l_district == district}
											onClick={() => {
												setState({
													...state,
													l_district: district,
													l_area: ''
												});
												handleClose2();
											}}>
											{district}
										</BookforItem>
									);
								})}
							</Popover>
						</Dropdown>
					</div>
				</div>
				<Label label='Area' required />
				<div className=' fullWidth mb20 '>
					<Dropdown value={state.l_area} placeHolder={'Select Area'} onClick={handleOpen3} fullWidth>
						<Popover variant='fullWidth' open={open3} handleClose={handleClose3}>
							<div className='p_10 flex justify-between'>
								<p className='ltbody_lg__semibold'>Select Area</p>
								<i onClick={handleClose3} className='fa-solid fa-xmark fa-lg' />
							</div>
							<Line />
							{areas &&
								areas?.map((area) => {
									return (
										<BookforItem
											key={area}
											checked={state.l_area == area}
											onClick={() => {
												setState({ ...state, l_area: area });
												handleClose3();
											}}>
											{area}
										</BookforItem>
									);
								})}
						</Popover>
					</Dropdown>
				</div>
				<div className={styles.sampleCollectionAddress_row1}>
					<Input
						variant='outlined'
						value={state.ul_address}
						onChange={(e) => setState((prev) => ({ ...prev, ul_address: e.target.value }))}
						placeholder='Type House, Flat No, Road'
						label='Address'
						required
					/>
				</div>

				<Label label='Address Type' required />
				<div className={styles.sampleCollectionAddress_row1}>
					<Button
						variant={ul_type === 'Home' ? 'bgPrimary' : 'bgWhitePrimary'}
						onClick={(e) => setState({ ...state, ul_type: 'Home' })}
						iconLeft={<span className='material-symbols-outlined'>home</span>}>
						Home
					</Button>
					<Button
						variant={ul_type === 'Office' ? 'bgPrimary' : 'bgWhitePrimary'}
						onClick={(e) => setState({ ...state, ul_type: 'Office' })}
						iconLeft={<i className='fa-solid fa-briefcase' />}>
						Office
					</Button>
					<Button
						variant={ul_type === 'Home Town' ? 'bgPrimary' : 'bgWhitePrimary'}
						onClick={(e) => setState({ ...state, ul_type: 'Home Town' })}
						iconLeft={<span className='material-symbols-outlined'>apartment</span>}>
						Home Town
					</Button>
				</div>
				<Button onClick={handleSubmit} variant='bgPrimary' fullWidth>
					{Object.keys(addressEdit).length > 0 ? 'Update Address' : 'Save Address'}
				</Button>
			</div>
		</div>
	);
};

export default SampleCollectionAddress;

/*
l_area
: 
"Nacnapara (Patharghata)"
l_de_id
: 
143
l_district
: 
"Barguna"
l_division
: 
"Barisal"
l_id
: 
3163
l_postcode
: 
8720
l_warehouse_id
: 
1
u_id
: 
447444
ul_address
: 
"Mirpur 10"
ul_default
: 
0
ul_id
: 
300723
ul_is_checked
: 
0
ul_lat
: 
0
ul_location
: 
"Mirpur 10, Nacnapara (Patharghata), Barguna, Barisal"
ul_long
: 
0
ul_mobile
: 
"+8801826391501"
ul_name
: 
"Md. Kamrul Islam"
ul_status
: 
1
ul_type
: 
"Office"
*/
