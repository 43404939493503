import styles from './Spinner.module.scss';

interface I_Props {
	size?: 'sm';
}

const Spinner = ({ size }: I_Props) => {
	return <div className={`${styles.spinner} ${size ? styles[size] : ''}`} />;
};

export default Spinner;
